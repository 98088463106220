import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取Brand列表
export const getBrandList=(params)=>{
    return getRequest(API.brandWriteOffList,params);
}
// Brand詳情
export const brandWriteOffDetailList=(params)=>{
    return getRequest(API.brandWriteOffDetailList,params);
}
// 導出Brand
export const exportBrandWriteOffList=(params)=>{
    return postRequest(API.exportBrandWriteOffList,params);
}
// 導出Brand詳情
export const exportBrandWriteOffDetailList=(params)=>{
    return postRequest(API.exportBrandWriteOffDetailList,params);
}

export const brandBieList=(params)=>{
    return getRequest(API.brandBieList,params);
}

// 獲取抵用券
export const getCouponList=(params)=>{
    return getRequest(API.questionnaireCoupon,params);
}
// 店別列表
export const getCounterList=(params)=>{
    return getRequest(API.shopList,params);
}
